export const supportedLanguages = [
  'en',
  'de',
  'es',
  'es-mx',
  'fr',
  'it',
  'ja',
  'ko',
  'pl',
  'pt-br',
  'ru',
  'zh-chs',
  'zh-cht',
];

export const unsupported = {
  en: 'The DIM team does not support using this browser. Some or all DIM features may not work.',
  de: 'Das DIM-Team unterstützt diesen Browser nicht. Einige oder alle DIM-Funktionen funktionieren möglicherweise nicht.',
  es: 'El equipo de DIM no soporta usar este navegador. Algunas o todas las características de DIM podrían no funcionar.',
  'es-mx':
    'El equipo de DIM no soporta usar este navegador. Algunas o todas las características de DIM podrían no funcionar.',
  fr: "L'équipe DIM ne prend pas en charge ce navigateur. Certaines ou toutes les fonctionnalités de DIM peuvent ne pas fonctionner.",
  it: "Il team di DIM non supporta l'utilizzo di questo browser. Alcune o tutte le funzioni di DIM potrebbero non funzionare.",
  ja: 'DIM チームはこのブラウザの利用をサポートしていません。DIM の一部または全ての機能が動作しない可能性があります。',
  ko: 'DIM 팀은 이 브라우저를 지원하지 않습니다. DIM의 일부나 전체 기능이 동작하지 않을 수 있습니다.',
  pl: 'Zespół DIM nie obsługuje tej przeglądarki. Niektóre lub wszystkie funkcje DIM mogą nie działać.',
  'pt-br':
    'A equipe do DIM não oferece suporte a este navegador. Alguns recursos do DIM podem não funcionar.',
  ru: 'Команда DIM не поддерживает использование этого браузера. Некоторые или все функции DIM могут не работать.',
  'zh-chs': 'DIM 团队不支持使用此浏览器。DIM 可能部分或完全不可用。',
  'zh-cht': 'DIM團隊不支持使用此瀏覽器。 DIM可能部分或完全不可用。',
};

export const steamBrowser = {
  en: 'The Steam overlay browser is very old and some or all DIM features may not work. We cannot provide support for it.',
  de: 'Es sieht so aus, als ob diese Seite im Steam-Browser geladen ist. Aufgrund seiner begrenzten Funktionen und Ressourcen, kann DIM eventuell unerwartet oder zeitweilig nicht laufen. Wir können dafür keinen Support bieten.',
  es: 'Parece ser que esta página está cargada en el navegador de Steam. Debido a sus limitadas caracarísticas y recursos, podría fallar inesperadamente o intermitentemente al ejecutar DIM. No podemos proveer soporte para ello.',
  'es-mx':
    'Parece ser que esta página está cargada en el navegador de Steam. Debido a sus limitadas características y recursos podría fallar inesperadamente o intermitentemente al ejecutar DIM. No podemos proveer soporte para ello.',
  fr: 'Il semble que cette page est chargé dans le navigateur Steam. À cause de ses fonctionnalités et ressources limitées, il pourrait échouer à lancer DIM de manière inattendue ou intermittente. Nous ne pouvons pas fournir de support pour cela.',
  it: 'Sembra che questa pagina sia caricata tramite il browser di Steam. A causa delle sue risorse e caratteristiche limitate, potrebbe non riuscire ad eseguire DIM correttamente. Non possiamo fornire supporto a riguardo.',
  ja: 'Steam ブラウザでこのページを開いていると検出しました。このブラウザは機能が限られていて予期しない不具合が突然起きる可能性があります。それらの不具合に対してサポート外となります。',
  ko: '이 페이지가 Steam 브라우저에서 로드된 것 같습니다. 한정된 기능과 자원으로 인해, 예기치 않게 혹은 간헐적으로 DIM을 실행에 실패할 수 있습니다. 이에 대한 지원은 제공되지 않습니다.',
  pl: 'Wygląda na to, że ta strona jest załadowana w przeglądarce Steam. Ze względu na ograniczone funkcje i zasoby, DIM może nie działać. Nie możemy zapewnić wsparcia dla tej przeglądarki.',
  'pt-br':
    'Parece que esta página foi aberta no navegador do Steam. Devido à limitações, a execução do DIM pode falhar de forma inesperada ou intermitente. Nós não podemos oferecer suporte a ele.',
  ru: 'Похоже, эта страница загружена в браузере Steam. Из-за его ограниченных возможностей и ресурсов, он может неожиданно или периодически не запускать DIM. Мы не можем предоставить помощь с этим.',
  'zh-chs':
    '这个页面似乎是在 Steam 的浏览器里打开的。因为 Steam 浏览器的功能和资源限制，DIM 可能会出现问题。我们无法为此提供支持。',
  'zh-cht':
    '這個頁面似乎是在Steam的瀏覽器裏打開的。 因為Steam瀏覽器的功能和資源限制，DIM可能會出現問題。 我們無法為此提供支援。',
};

export const samsungInternet = {
  en: 'Samsung Internet can make sites look too dark when dark mode is on. Enable Settings > Labs > Use website dark theme or switch to another browser.',
  de: 'Samsung Internet can make sites look too dark when dark mode is on. Enable Settings > Labs > Use website dark theme or switch to another browser.',
  es: 'Samsung Internet can make sites look too dark when dark mode is on. Enable Settings > Labs > Use website dark theme or switch to another browser.',
  'es-mx':
    'Samsung Internet can make sites look too dark when dark mode is on. Enable Settings > Labs > Use website dark theme or switch to another browser.',
  fr: 'Samsung Internet can make sites look too dark when dark mode is on. Enable Settings > Labs > Use website dark theme or switch to another browser.',
  it: 'Samsung Internet can make sites look too dark when dark mode is on. Enable Settings > Labs > Use website dark theme or switch to another browser.',
  ja: 'Samsung Internet can make sites look too dark when dark mode is on. Enable Settings > Labs > Use website dark theme or switch to another browser.',
  ko: 'Samsung Internet can make sites look too dark when dark mode is on. Enable Settings > Labs > Use website dark theme or switch to another browser.',
  pl: 'Samsung Internet can make sites look too dark when dark mode is on. Enable Settings > Labs > Use website dark theme or switch to another browser.',
  'pt-br':
    'Samsung Internet can make sites look too dark when dark mode is on. Enable Settings > Labs > Use website dark theme or switch to another browser.',
  ru: 'Samsung Internet can make sites look too dark when dark mode is on. Enable Settings > Labs > Use website dark theme or switch to another browser.',
  'zh-chs':
    'Samsung Internet can make sites look too dark when dark mode is on. Enable Settings > Labs > Use website dark theme or switch to another browser.',
  'zh-cht':
    'Samsung Internet can make sites look too dark when dark mode is on. Enable Settings > Labs > Use website dark theme or switch to another browser.',
};
